export const commonMixin = {
	methods: {
		getFareName(type, price) {
			var formatter = new Intl.NumberFormat("ru-KZ", {
				style: "currency",
				currency: "KZT"
			});
			var formattedPrice = formatter.format(price);

			switch (+type) {
				case 1:
					return `${formattedPrice}/час`;
				case 2:
					return `${formattedPrice}/месяц`;
				default:
					return "Error";
			}
		},

		getIdentityTypeName(type) {
			switch (+type) {
				case 1:
					return "RFID"
				case 2:
					return "ГРНЗ"
				default:
					return "Error";
			}
		},

		getFullName(lastName, firstName) {
			let name = lastName || ''
			name += (firstName ? ` ${firstName}` : '')
			return name
		}
	}
}