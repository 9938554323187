<template>
  <v-card>
    <v-card-title>
      Платежи
      <v-spacer />
      <div>
        <div class="font-weight-light">
          Кол-во платежей: {{ statements.length }}
        </div>
        <div class="font-weight-light">
          Общая сумма: {{ totalAmount | money }} ₸
        </div>
      </div>
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Номер тел</th>
            <th class="text-left">EAN13</th>
            <th class="text-left">ФИО</th>
            <th class="text-left">Номер транзакции</th>
            <th class="text-left">Сумма</th>
            <th class="text-left">Дата и время</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in statements" :key="item.id">
            <td>{{ item.phoneNumber }}</td>
            <td>{{ item.ean13 }}</td>
            <td>{{ `${item.lastName || ''} ${item.firstName || ''}` }}</td>
            <td>{{ item.transactionId }}</td>
            <td>{{ `${item.amount - item.fee} ₸` }}</td>
            <td>{{ item.dateTime | date }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      statements: (state) => state.statements.items,
      totalAmount: (state) => state.statements.items.reduce((a, b) =>  a + (b.amount - b.fee), 0),
    }),
  },
};
</script>
