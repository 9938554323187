<template>
  <div>
    <StatementsFilter class="mt-4" />
    <StatementsTable class="mt-8" />
  </div>
</template>

<script>
import StatementsFilter from "./StatementsFilter";
import StatementsTable from "./StatementsTable";

import { mapActions } from "vuex";

export default {
  components: {
    StatementsFilter,
    StatementsTable,
  },
  methods: mapActions("statements", ["fetchStatements"]),
  mounted() {
    this.fetchStatements();
  },
};
</script>