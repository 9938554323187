<template>
  <div>
    <ApplicationsTable @click="openApplicationDialog" />
    <v-dialog v-model="openDialog" max-width="500">
      <ApplicationHandle
        v-if="openDialog"
        @close="closeApplicationDialog"
        :application="selectedApplication"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ApplicationsTable from "./ApplicationsTable";
import ApplicationHandle from "./ApplicationHandle";

export default {
  components: {
    ApplicationsTable,
    ApplicationHandle,
  },
  data() {
    return {
      openDialog: false,
      selectedApplication: null,
    };
  },
  methods: {
    openApplicationDialog(value) {
      this.openDialog = true;
      this.selectedApplication = value;
    },
    closeApplicationDialog() {
      this.openDialog = false;
      this.selectedApplication = null;
    },
    ...mapActions("applications", ["fetchApplications"]),
    ...mapActions("fares", ["fetchFares"]),
  },
  beforeMount() {
    this.fetchApplications();
    this.fetchFares();
  },
};
</script>