<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" app clipped>
      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click.stop="toggleNavigation"></v-app-bar-nav-icon>
      <v-toolbar-title
        class="headline font-weight-light mr-10 d-none d-sm-block"
      >
        <span class="green--text">Mega</span>
        <span class="primary--text">Pass</span>
        <span class="red--text"> Apartments</span>
      </v-toolbar-title>

      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        absolute
        bottom
        color="blue"
        :height="3"
      ></v-progress-linear>

      <v-toolbar-items>
        <!-- <v-btn text class="font-weight-regular">Стикеры</v-btn> -->
      </v-toolbar-items>
      <div class="flex-grow-1"></div>
      <v-btn text class="font-weight-regular" @click="logout">
        <span class="d-none d-sm-block">Выход</span>
        <v-icon right>exit_to_app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: this.$vuetify.breakpoint.mdAndUp,
      mini: false,
      items: [
        {
          title: "Заявки",
          icon: "content_paste",
          route: { name: "applications" },
        },
        {
          title: "Пропуски",
          icon: "style",
          route: { name: "passes" },
        },
        {
          title: "Выписка",
          icon: "receipt_long",
          route: { name: "statements" },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.progressBar.isLoading,
    }),
  },
  methods: {
    toggleNavigation() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.mini = !this.mini;
      } else {
        this.drawer = !this.drawer;
      }
    },
    ...mapActions(["logout"]),
  },
};
</script>
