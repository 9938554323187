<template>
  <v-card class="px-4">
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col cols="6">
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateFrom"
                  label="С"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                first-day-of-week="1"
                @input="menuDateFrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="menuDateTo"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateTo"
                  label="По"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                first-day-of-week="1"
                @input="menuDateTo = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-btn color="primary" @click="apply">Применить</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    menuDateFrom: false,
    menuDateTo: false,

    dateFrom: "",
    dateTo: "",
    ean13: "",
  }),
  computed: {
    ...mapState({
      dateFromState: (state) =>
        moment(state.statements.dateFrom).format("YYYY-MM-DD"),
      dateToState: (state) =>
        moment(state.statements.dateTo).format("YYYY-MM-DD"),
    }),
  },
  methods: {
    apply() {
      this.filterStatements({
        dateFrom: moment(this.dateFrom).startOf("date").toDate(),
        dateTo: moment(this.dateTo).endOf("date").toDate(),
        ean13: this.ean13,
      });
    },
    ...mapActions("statements", ["filterStatements"]),
  },
  mounted() {
    this.dateFrom = this.dateFromState;
    this.dateTo = this.dateToState;
  },
};
</script>