<template>
  <PassesTable />
</template>

<script>
import PassesTable from "./PassesTable";

import { mapActions } from "vuex";

export default {
  components: {
    PassesTable,
  },
  methods: mapActions("passes", ["fetchPasses"]),
  beforeMount() {
    this.fetchPasses();
  },
};
</script>