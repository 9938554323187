<template>
  <div>
    <v-card>
      <v-card-title>
        Пропуски
        <v-btn depressed class="ml-3" @click="dialogAdd = true">
          <v-icon left>add</v-icon>
          Добавить
        </v-btn>
        <v-spacer />
        <p class="font-weight-light">Кол-во пропусков: {{ passes.length }}</p>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Номер телефона</th>
                <th class="text-left">EAN13</th>
                <th class="text-left">ФИО</th>
                <th class="text-left">Тариф</th>
                <th class="text-left">Дата создания</th>
                <th class="text-left">Дата истечения</th>
                <th class="text-left">Автоплатеж</th>
                <th class="text-left">Действия</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in passes"
                :key="item.id"
                :class="{ 'error--text': isExpired(item.expireDate) }"
              >
                <td>{{ item.phoneNumber }}</td>
                <td>{{ item.ean13 }}</td>
                <td>
                  {{ `${item.lastName || ""} ${item.firstName || ""}` }}
                  <v-tooltip right v-if="item.comment">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on"> info </v-icon>
                    </template>
                    <span>{{ item.comment }}</span>
                  </v-tooltip>
                </td>
                <td>{{ `${item.fareAmount} тг` }}</td>
                <td>{{ item.createDate | date }}</td>
                <td>{{ item.expireDate | date }}</td>
                <td>{{ item.autoPay ? "Вкл" : "Выкл" }}</td>
                <td>
                  <template
                    v-if="item.fareAmount === 0 || isExpired(item.expireDate)"
                  >
                    <v-btn @click="requestToDelete(item.id)" icon>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogAdd" max-width="500">
      <PassAdd v-if="dialogAdd" @close="dialogAdd = false" />
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500">
      <ConfirmDialog
        title="Подтверждение удаления"
        text="Вы действительно хотите удалить пропуск?"
        @confirm="confirmDelete"
        @close="dialogDelete = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import PassAdd from "./PassAdd";
import ConfirmDialog from "../ConfirmDialog.vue";

export default {
  components: {
    PassAdd,
    ConfirmDialog,
  },
  data() {
    return {
      dialogAdd: false,
      dialogDelete: false,
      passIdToDelete: null,
    };
  },
  computed: {
    ...mapState({
      passes: (state) => state.passes.passes,
    }),
  },
  methods: {
    isExpired(date) {
      return moment(date).isBefore(moment(), "day");
    },
    requestToDelete(id) {
      this.passIdToDelete = id;
      this.dialogDelete = true;
    },
    confirmDelete() {
      this.deletePass(this.passIdToDelete).then(() => {
        this.dialogDelete = false;
      });
    },
    ...mapActions("passes", ["deletePass"]),
    ...mapActions(["showAlert"]),
  },
};
</script>