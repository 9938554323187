<template>
  <v-card>
    <v-card-title
      class="headline grey lighten-2 d-flex justify-space-between"
      primary-title
      >Добавление технического пропуска</v-card-title
    >
    <v-card-text class="text--primary mt-3">
      <v-text-field v-model="ean13" label="EAN13*" outlined></v-text-field>
      <v-text-field
        v-model="phoneNumber"
        label="Номер тел"
        outlined
      ></v-text-field>
      <v-row>
        <v-col cols="6"
          ><v-text-field
            v-model="lastName"
            label="Фамилия"
            outlined
          ></v-text-field
        ></v-col>
        <v-col cols="6"
          ><v-text-field v-model="firstName" label="Имя" outlined></v-text-field
        ></v-col>
      </v-row>
      <v-text-field
        v-model="comment"
        label="Комментарий"
        outlined
      ></v-text-field>
      <v-menu
        v-model="menuExpireDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="expireDate"
            label="Дата истечения"
            prepend-inner-icon="mdi-calendar"
            append-icon="clear"
            readonly
            outlined
            @click:append="expireDate = ''"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="expireDate"
          @input="menuExpireDate = false"
        ></v-date-picker>
      </v-menu>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" text>Отмена</v-btn>
      <v-btn
        @click="save"
        :disabled="!(ean13 && ean13.length === 13) || isLoading"
        color="error"
        text
        >Да</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      ean13: "",
      phoneNumber: "",
      lastName: "",
      firstName: "",
      comment: "",
      expireDate: "",
      menuExpireDate: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.progressBar.isLoading,
    }),
  },
  methods: {
    save() {
      const pass = {
        ean13: this.ean13,
        phoneNumber: this.phoneNumber,
        lastName: this.lastName,
        firstName: this.firstName,
        expireDate: this.expireDate
          ? moment(this.expireDate, "YYYY-MM-DD").toDate()
          : null,
      };
      this.addPass(pass).then(() => this.$emit("close"));
    },
    ...mapActions("passes", ["addPass"]),
  },
};
</script>