<template>
  <v-card>
    <v-card-title class="headline">Обработка заявки</v-card-title>
    <v-card-text>
      <v-radio-group v-model="isApproved" row>
        <v-radio label="Одобрить" color="success" :value="true"></v-radio>
        <v-radio label="Отклонить" color="error" :value="false"></v-radio>
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        @click="apply"
        :disabled="isLoading || isApproved === null"
        :loading="isLoading"
        color="primary"
        text
        >Отправить</v-btn
      >
      <v-btn @click="$emit('close')" :disabled="isLoading" text>Отмена</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: ["application"],
  data() {
    return {
      isApproved: null,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.progressBar.isLoading,
    }),
  },
  methods: {
    apply() {
      if (this.isApproved) {
        this.approve(this.application.id).then(() => this.$emit("close"));
      } else {
        this.decline(this.application.id).then(() => this.$emit("close"));
      }
    },
    ...mapActions("applications", ["approve", "decline"]),
  },
};
</script>