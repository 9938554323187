<template>
  <v-card>
    <v-card-title>
      Заявки
      <v-spacer />
    </v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Номер телефона</th>
            <th class="text-left">Имя</th>
            <th class="text-left">Фамилия</th>
            <th class="text-left">Комментарий</th>
            <th class="text-left">Ean13</th>
            <th class="text-left">Дата и время подачи</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in applications"
            :key="item.id"
            @click="$emit('click', item)"
          >
            <td>{{ item.phoneNumber }}</td>
            <td>{{ item.firstName }}</td>
            <td>{{ item.lastName }}</td>
            <td>{{ item.comment }}</td>
            <td>{{ item.ean13 }}</td>
            <td>{{ item.createDate | fulldate }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { commonMixin } from "../../mixins/commonMixin.js";

export default {
  mixins: [commonMixin],
  computed: {
    ...mapState({
      applications: (state) => state.applications.items,
    }),
  },
};
</script>

<style>
</style>